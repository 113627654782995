import React, { useEffect, useState } from 'react';
import { Icons, SizedIcon } from '../../../icons';
import { DEVELOPER_FLAGS, isDeveloperAuthorized } from '../../services/util/developer';

type CountdownProps = {
    date: Date;
    isAnniversary: boolean;
    setIsAnniversary: Function;
};

const Countdown = ({ date, isAnniversary, setIsAnniversary }: CountdownProps) => {
    const [anniversaryDate, setNextAnniversaryDate] = useState(date);
    const [loaded, setLoaded] = useState(false);
    const [dateFields, setDateFields] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        if (isDeveloperAuthorized(DEVELOPER_FLAGS.ANNIVERSARY_DATE)) {
            setIsAnniversary(true);
            setLoaded(true);
            return;
        }

        const zeroIfNegative = (value: number) => value < 0 ? 0 : value;
        const isAnniversaryDate = () => dateFields.days === 0 && Object.values(dateFields).every(value => value <= 0);

        const updateCountdown = () => {
            let millis = anniversaryDate.getTime() - new Date().getTime();
            if (millis < -86400000) {
                setIsAnniversary(false);
                setNextAnniversaryDate(new Date(
                    anniversaryDate.getFullYear() + 1,
                    anniversaryDate.getMonth(),
                    anniversaryDate.getDate()
                ));
                setLoaded(true);
                return;
            }

            if (millis < 0 && isAnniversaryDate()) {
                setIsAnniversary(true);
                setLoaded(true);
                return;
            }
            if (isAnniversary) return;

            const days = Math.floor(zeroIfNegative(millis / 86400000));
            millis = millis - (days * 86400000);
            const hours = Math.floor(zeroIfNegative(millis / 3600000));
            millis = millis - (hours * 3600000);
            const minutes = Math.floor(zeroIfNegative(millis / 60000));
            millis = millis - (minutes * 60000);
            const seconds = Math.floor(zeroIfNegative(millis / 1000));
            setDateFields({
                days,
                hours,
                minutes,
                seconds
            });
            setLoaded(true);
        };

        const updateInterval = setInterval(updateCountdown, 1000);
        return () => clearInterval(updateInterval);
    }, [anniversaryDate, dateFields, isAnniversary, setIsAnniversary]);

    const AnniversaryLabel = () => (
        <div className="flex justify-center items-center">
            <SizedIcon size={8} className="fill-current text-red-200 mr-4">
                <Icons.Heart />
            </SizedIcon>
            <span>Happy Anniversary!</span>
            <SizedIcon size={8} className="fill-current text-red-200 ml-4">
                <Icons.Heart />
            </SizedIcon>
        </div>
    );

    return (
        <div className="bg-gray-800 rounded-2xl">
            <div className="text-center p-4 text-white text-lg font-bold">
                { isAnniversary ?
                    <AnniversaryLabel /> :
                    <span>
                        {
                            `${loaded ? dateFields.days : '-- '}d ` +
                            `${loaded ? dateFields.hours : '-- '}h ` +
                            `${loaded ? dateFields.minutes : '-- '}m ` +
                            `${loaded ? dateFields.seconds : '-- '}s`
                        }
                    </span>
                }
            </div>
        </div>
    )
};

export default Countdown;