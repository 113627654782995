export const isMobile = () => {
	return window.innerWidth <= 680;
}

export const getElementById = (id) => {
	const element = document.getElementById(id);

	if (!element)
		throw Error(`There are no elements in the DOM with the id ${id}`);

	return element;
}

export const querySelector = (parent, selector) => {
	if (!parent)
		throw Error('An invalid parent HTMLElement was passed to query');

	const elements = parent.querySelector(selector);

	if (!elements)
		throw Error(`There are no child elements for ${parent.id} with the selector ${selector}`);

	return elements;
}

export const distanceToCenterX = (element) => {
	if (!element)
		throw Error('Attempted to determine the distance to the center X of an invalid parent HTMLElement');

	return Math.round(element.offsetWidth / 2);
}

export const distanceToCenterY = (element) => {
	if (!element)
		throw Error('Attempted to determine the distance to the center Y of an invalid parent HTMLElement');

	return Math.round(element.offsetHeight / 2);
}

export const scrollToElement = (element, duration, offset) => {
	if (duration <= 0) {
		return;
	}
	
	const top = element.getBoundingClientRect().top;
	let distance = (top - offset) / duration * 10;
	const scroll = window.pageYOffset + distance;

    setTimeout(function() {
    	window.scrollTo(0, (scroll <= 0 ? 0 : scroll));
        scrollToElement(element, duration - 10, offset);
    }, 10);
}

export const toNumber = (str, fallback = 0) => {
	if (!str)
		return fallback;

	return Number(str.replace('px', ''));
}