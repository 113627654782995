import React, { useState } from 'react';
import { shuffleArray } from '../../services/util/array';
import { DEVELOPER_FLAGS, isDeveloperAuthorized } from '../../services/util/developer';
import SlideshowElement from '../slideshow/element/slideshow-element';
import TimedSlideshow from '../slideshow/timed-slideshow';

type ReviewSectionProps = {
    date: Date;
    images: string[];
    shuffle: boolean;
    locked: boolean;
    children: any;
    startVerification?: () => void;
};

const ReviewSection = ({ date, images, shuffle, locked, children, startVerification }: ReviewSectionProps) => {
    const [showingMessage, setShowingMessage] = useState(false);

    const year = date.getFullYear() - 1;
    const afterDate = isDeveloperAuthorized(DEVELOPER_FLAGS.ANNIVERSARY_DATE) || new Date().getTime() > date.getTime();
    const imageSources: string[] = images.map(key => `images/${year}/${key}`);
    if (shuffle) shuffleArray(imageSources);

    const height = '512px';
    const width = '384px';

    const slideElements = imageSources.map((src, i) => (
        <SlideshowElement style={{ height, width }} id={`${year}-${i}`} key={i}>
            <img loading="lazy" src={src} alt={`${year} Slideshow (${i})`} />
        </SlideshowElement>
    ));

    const handleShowMessage = () => {
        if (locked) {
            if (afterDate && startVerification) startVerification();
            return;
        }
        setShowingMessage(afterDate && !showingMessage);
    }

    return (
        <div className="m-auto border-solid border-t-2 border-b-2 overflow-hidden">
            <div className="absolute z-10 left-1 right-1 m-4">
                <div className="flex flex-wrap justify-center sm:justify-start">
                    <div className={`py-2 px-6 min-w-full sm:min-w-0 rounded${showingMessage ? '-t-' : '-'}xl bg-white`}>
                        <div className="flex justify-between sm:justify-start items-center">
                            <span className="font-bold">{`${year} - ${year + 1}`}</span>
                            <button
                                disabled={(locked || !afterDate) && !handleShowMessage}
                                className="ml-5 font-medium px-3 py-1 rounded-md bg-gray-200"
                                onClick={handleShowMessage}
                            >
                                { !afterDate ?
                                    'Locked' :
                                    locked ? 'Verify to view' : `${showingMessage ? 'Hide' : 'Show'} message`
                                }
                            </button> 
                        </div>
                    </div>
                    <div className={`transition duration-500 ease-in-out ${!showingMessage ? 'hidden' : ''} min-w-full rounded-b-xl sm:rounded-tr-xl sm:min-w-0 py-4 bg-white`}>
                        <div className="overflow-y-scroll px-6 max-h-80">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
            <TimedSlideshow 
                animationDelay="1500"
                spaceBetween="0"
                looping={true}
                inverted={true}
                elements={slideElements}
                className={`w-full ${locked ? 'filter grayscale blur' : ''}`}
                height={height}>
            </TimedSlideshow>
        </div>
    )
};

export default ReviewSection;