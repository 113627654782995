import React from 'react';
import { Icons, SizedIcon } from '../../../icons';

type ModalProps = {
    title: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    children: any;
};

const MODAL_CONTENT_ID = 'modal-content';

const Modal = ({ title, open, setOpen, children }: ModalProps) => {
    const contentScrollTo = (top: number) => window.document.getElementById(MODAL_CONTENT_ID)?.scrollTo({ top });

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { contentScrollTo } as Partial<unknown>);
        }
        return child;
      });
    return (
        <div className={`${open ? '' : 'hidden'} fixed z-50 top-0 bottom-0 left-0 right-0`}>
            { open && <div className="w-full h-full bg-gray-800 opacity-60" />}
            { open && (
                <div className="absolute top-0 bottom-0 left-0 right-0 m-4">
                    <div className="flex justify-center mx-2 my-8 md:mx-8">
                        <div className="w-full max-w-2xl py-4 px-8 rounded-xl bg-white">
                            <button
                                className="float-right"
                                onClick={() => setOpen(false)}
                            >
                                <SizedIcon size={8}>
                                    <Icons.Close />
                                </SizedIcon>
                            </button>
                            <h3 className="text-2xl font-bold mb-2">{title}</h3>
                            <div id={MODAL_CONTENT_ID} className="overflow-x-hidden overflow-y-scroll" style={{ maxHeight: '70vh'}}>
                                {childrenWithProps}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
};

export default Modal;