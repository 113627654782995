import React, { Component } from 'react';
import * as Util from './util';

import './slideshow.css';

export const navigationStyle = {
	HIDDEN: 0,
	STANDARD: 1,
	ROUNDED: 2,
	CIRCULAR: 3
}

class Slideshow extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
        	elements: (props.elements ? props.elements : []),
        	spaceBetween: Util.toNumber(props.spaceBetween),
        	activeIndex: Util.toNumber(props.activeIndex),
        	inverted: (props.inverted ? props.inverted : false),
			looping: (props.looping ? props.looping : false),
			height: Util.toNumber(props.height),
			mbHeight: Util.toNumber(props.mbHeight),
			navStyle: (props.navStyle ? props.navStyle : navigationStyle.HIDDEN),
			navVisible: Util.isMobile(),
			style: (props.style ? props.style : null)
        };
    }
    
    componentDidMount(props) {
    	const state = this.state;
    	state.centerX = Util.distanceToCenterX(this.refs.container);
        state.spaceBetween = (props && props.spaceBetween ? Util.toNumber(props.spaceBetween) : state.spaceBetween);
        state.activeIndex = (props && props.activeIndex ? props.activeIndex : state.activeIndex);
        state.inverted = (props && props.inverted ? props.inverted : state.inverted);
		state.looping = (props && props.looping ? props.looping : state.looping);
		state.height = (props && props.height ? Util.toNumber(props.height) : state.height);
		state.mbHeight = (props && props.mbHeight ? Util.toNumber(props.mbHeight) : state.mbHeight);
        state.navStyle = (props && props.navStyle ? props.navStyle : state.navStyle);
		state.elements = (props && props.elements ? props.elememts : state.elements);
		state.style = (props && props.style ? props.style : state.style);
        
        if (state.looping && state.elements.length > 1) {
        	state.activeIndex = Math.round(state.elements.length / 2) - 1;
        }

        this.setState(state);
		this.updateContainerStyle();
		this.updateElementPositions();

		// Call an update shortly after to ensure elements positioned properly
		setTimeout(this.updateElementPositions, 100);
        
		window.addEventListener("routeChange", this.updateElementPositions);
		window.addEventListener("resize", this.onResize);
        window.addEventListener("mousemove", this.updateOnMouseMove);
    }
    
    componentWillUnmount() {
		window.removeEventListener("routeChange", this.updateElementPositions);
        window.removeEventListener("resize", this.onResize);
        window.removeEventListener("mousemove", this.updateOnMouseMove);
	}
	
	onResize = () => {
		this.updateContainerStyle();
		this.updateElementPositions();
		setTimeout(this.updateElementPositions, 1000);
	}
    
    navigateLeft = () => {
    	let index = this.state.activeIndex + (this.state.inverted ? 1 : -1);
    	
    	// If looping don't change the active index, change the element order
    	if (this.state.looping && this.state.elements.length > 1) {
    		const state = this.state;
    		let element = state.elements[0];
    		
    		if (!state.inverted) {
    			state.elements.shift();
    			state.elements.push(element);
    		}
    		else {
    			element = state.elements[state.elements.length - 1];
    			state.elements.pop();
    			state.elements.splice(0, 0, element);
    		}
    		
    		this._moveBehind(element.props.id);
    		this.setState(state);
    		index = this.state.activeIndex;
    	}
    	
    	this.setActiveElement(index);
    }
    
    navigateRight = () => {
    	let index = this.state.activeIndex + (this.state.inverted ? -1 : 1);
    	
    	// If looping don't change the active index, change the element order
    	if (this.state.looping && this.state.elements.length > 1) {
    		
    		const state = this.state;
    		let element = state.elements[state.elements.length - 1];
    		
    		if (!state.inverted) {
    			state.elements.pop();
    			state.elements.splice(0, 0, element);
    		}
    		else {
    			element = state.elements[0];
    			state.elements.shift();
    			state.elements.push(element);
    		}
    		
    		this._moveBehind(element.props.id);
    		this.setState(state);
    		index = this.state.activeIndex;
    	}
    	
    	this.setActiveElement(index);
    }
    
    setActiveElement = (index, updatePositions = true) => {
    	const state = this.state;
    	
    	if (state.elements.length === 0)
    		return;
    	
    	// Cycle to the opposite side of the array if the index is out of bounds
    	if (index < 0) {
    		index = state.elements.length - 1;
    	}
    	else if (index >= state.elements.length) {
    		index = 0;
    	}
    	
    	state.activeIndex = index;
    	this.setState(state);
    	
    	if (updatePositions)
    		this.updateElementPositions();
    }
    
    updateOnMouseMove = (event) => {
    	if (this.refs.container && this.refs.container.contains(event.target)) {
    		//console.log('inside');
    	}
	}
	
	updateContainerStyle = () => {
		let state = this.state;

		let height = Util.isMobile() ?
			(state.mbHeight ? state.mbHeight : state.height) :
			state.height;
			
		if (height) 
			state.style = {...state.style, height: (height > 0 ? height : this.state.style.height)};

		this.setState(state);
	}
    
    updateElementPositions = () => {
    	const active = this.state.activeIndex;
    	const elements = this.state.elements;
    	
    	if (!elements[active])
    		return;
    	
    	const activeElement = document.getElementById(elements[active].props.id);
    	
    	if (!activeElement || !this.refs.container)
    		return;
    	
    	let elementY = Util.distanceToCenterY(this.refs.container) - Math.round(activeElement.offsetHeight / 2);
    	let startX = Util.distanceToCenterX(this.refs.container) - Math.round(activeElement.offsetWidth / 2);
		let endX = startX + activeElement.offsetWidth;
		
    	this._setElementPosition(activeElement, startX, elementY);
    	
    	for (let i = active - 1; i >= 0; i--) {
    		
			let element = document.getElementById(elements[i].props.id);
			
			if (!element)
				continue;
    		
    		elementY = Util.distanceToCenterY(this.refs.container) - Math.round(element.offsetHeight / 2);
    		startX -= this.state.spaceBetween;
    		
    		this._setElementPosition(element, startX - element.offsetWidth, elementY);
    		startX -= element.offsetWidth;
    	}
    	
    	for (let i = active + 1; i < elements.length; i++) {
			let element = document.getElementById(elements[i].props.id);
			
			if (!element)
				continue;
    		
    		elementY = Util.distanceToCenterY(this.refs.container) - Math.round(element.offsetHeight / 2);
    		endX += this.state.spaceBetween;
    		
    		this._setElementPosition(element, endX, elementY);
    		endX += element.offsetWidth;
    	}
    }
    
    _moveBehind = (id) => {
		let element = document.getElementById(id);
		
    	if (!element)
    		return;
		
    	const current = element.style;
		element.style.zIndex = 0;
		element.style.opacity = 0;
		element.style.transition = 'opacity 0.1s, left 0.5s';
		
		setTimeout(() => {
			element.style.zIndex = (current.zIndex > 0 ? current.zIndex : null);
			element.style.opacity = (current.opacity > 0 ? current.opacity : null);
			element.style.transition = null;
		}, 500)
    }
    
    _setElementPosition = (element, x, y) => {
    	element.style.left = x + 'px';
		element.style.top = y + 'px';
    }

    render = () => {
    	let navLeft = undefined;
    	let navRight = undefined;
    	
    	if (this.state.navStyle === 1) {
    		navLeft = <button className="btn nav-left" onClick={this.navigateLeft}>{'<'}</button>;
    		navRight = <button className="btn nav-right" onClick={this.navigateRight}>{'>'}</button>;
		}
    	
        return(
            <div ref="container" className={'relative overflow-hidden ' + this.props.className} style={this.state.style}>
            	{navLeft}
            	{this.state.elements}
            	{navRight}
            </div>
        );
    }
}

export default Slideshow;