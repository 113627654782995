import React from 'react';
import { SizedIcon, Icons } from '../../../icons';
import { DEVELOPER_FLAGS, isDeveloperAuthorized, unauthorizeDeveloper } from '../../services/util/developer';
import Maintenance from '../maintenance/maintenance';

type DeveloperToggleProps = {
    enabled: boolean;
    children: any;
};

const DeveloperToggle = ({ enabled, children }: DeveloperToggleProps) => {
    return isDeveloperAuthorized(DEVELOPER_FLAGS.GLOBAL) ? (
        <div>
            <button
                className="absolute z-50 m-6 p-4 bg-red-500 rounded-full text-white"
                onClick={() => unauthorizeDeveloper(DEVELOPER_FLAGS.GLOBAL)}
            >
                <SizedIcon size={8}>
                    <Icons.Logout />
                </SizedIcon>
            </button>
            {children}
        </div>
    ) : enabled ? <Maintenance /> : children;
};

export default DeveloperToggle;