import React, { useState } from 'react';
import Countdown from '../../common/countdown/countdown';
import Verification from '../../common/verification/verification';
import ReviewSection from '../../common/review-section/review-section';
import Modal from '../../common/modal/modal';
import Quiz from '../../common/quiz/quiz';
import { QUIZ_QUESTIONS_2020, QUIZ_QUESTIONS_2021, QUIZ_QUESTIONS_2022 } from './questions';
import { shuffleArray } from '../../services/util/array';

const Anniversary = () => {
    const [verified, setVerified] = useState(false);
    const [isAnniversary, setIsAnniversary] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const getAnniversaryDate = (year: number) => new Date(year, 8, 26);

    return (
        <div className="m-auto">
            <div className="text-center pt-12">
                <h1 className="text-3xl font-bold">Sarah and Jacob</h1>
                <h2>09-26-2020</h2>
            </div>

            <div className="transition-all duration-500">
                <div className="m-auto max-w-lg px-4 pt-6 pb-12">
                    <Countdown
                        date={getAnniversaryDate(2023)}
                        isAnniversary={isAnniversary}
                        setIsAnniversary={setIsAnniversary}
                    />
                </div>

                <ReviewSection
                    date={getAnniversaryDate(2023)}
                    images={require.context(`../../../../public/images/2022`, false, /\.(png|jpeg|jpg|svg)$/).keys()}
                    shuffle={true}
                    locked={!verified}
                    startVerification={() => setIsModalOpen(true)}
                >
                    <p className="font-medium">Sarah,</p>
                    <br/>
                    <p>
                        Well it's been 3 years now! It's crazy how quickly time passes by, but, I guess when you're with the right person it's natural to feel like every moment is fleeting as you cherrish every second with them, that's how it feels with you.
                    </p>
                    <br/>
                    <p>
                        Yet again we have SO MUCH to look back on this year, we're constantly globe trotting and experiencing new things together, and I wouldn't have it any other way. Whether it be St Paddy's day in the land of the leprechauns or relaxing on those Nido sunbeds (or by the private pool) with that FRESHHHH Greek feta and yogurt we've been able to spend some well deserved quality time together depsite the outside pressures of life.
                    </p>
                    <br/>
                    <p>
                        I can imagine this year will be full of just as many adventures, although, our time spent together one-on-one just us relaxing in the house is just as valuable to me. We're going to continue growing as we take on this next chapter of life living together, preparing us for our magical day that we can hopefully start planning for this year. I love spending every minute with you baby, I want you to always know that.
                    </p>
                    <br/>
                    <p>
                        I have a gut feeling that 2023-2024 is going to be one of our best years yet, both individually, and together as partners. I'm so grateful and fortunate to have found my soulmate so early in life.
                    </p>
                    <br/>
                    <p>
                        I love you so much Sarah and I can't wait to embark on another year of our lives together! ❤️
                    </p>
                    <br/>
                    <p className="font-medium">- Jake</p>
                </ReviewSection>

                <br/>
                <br/>

                <ReviewSection
                    date={getAnniversaryDate(2022)}
                    images={require.context(`../../../../public/images/2021`, false, /\.(png|jpeg|svg)$/).keys()}
                    shuffle={true}
                    locked={!verified}
                    startVerification={() => setIsModalOpen(true)}
                >
                    <p className="font-medium">Sarah,</p>
                    <br/>
                    <p>
                        Well here we are, two years in. Who would've thought that a single swipe would lead us to each other in this crazy world!
                    </p>
                    <br/>
                    <p>
                        This second year has definitely had it's highs, along with it's lows. We've been through a lot together, and honestly I know it's been especially difficult for you. Looking through the photos from this year though there is one thing that's evident, and that is, when we're together we constantly seem to find ourselves smiling and enjoying being with one another.
                    </p>
                    <br/>
                    <p>
                        It's almost hard to believe how many things we've actually done in the past year, there's no other point in my life i've been able to do as much as I have with you. Whether it was catching some rays in Aruba, enjoying some sushi on the West Coast, having the opportunity to explore your homeland and meet your close family (which genuinely means so much to me), and now embracing new opportunities in the great UK, I couldn't imagine my life without you. You make me happy every day, and I know we bicker sometimes, but overall I feel like we have something which many people could only hope for.
                    </p>
                    <br/>
                    <p>
                        We're continuing to grow as both individuals and as a couple, and we have so many opportunities ahead of us. I honestly can't wait to see what this year holds for us. I know I probably say it a lot, but I hope you aren't tired of me saying, I love you so much!
                    </p>
                    <br/>
                    <p>
                        Ti amo tanto, sempre e per sempre! ❤️
                    </p>
                    <br/>
                    <p className="font-medium">- Jake</p>
                </ReviewSection>

                <br/>
                <br/>

                <ReviewSection
                    date={getAnniversaryDate(2021)}
                    images={require.context(`../../../../public/images/2020`, false, /\.(png|jpeg|svg)$/).keys()}
                    shuffle={true}
                    locked={!verified}
                    startVerification={() => setIsModalOpen(true)}
                >
                    <p className="font-medium">Sarah,</p>
                    <br/>
                    <p>
                        It's hard to believe that we've already been together for an entire year. Despite it only being a year it feels like we've known each other for so much longer.
                    </p>
                    <br/>
                    <p>
                        Looking back on it we've been able to experience so much together even with the obstacles in our way (talking about you COVID). We've been able to explore the city together taking frequent walks, and of course going on all of our trips to Yorkville. Not only did we explore the city, we hit the slopes, relaxed on the beach, had a weekend getaway on the lake, and so much more. I can't go without mentioning all of the dinners we've had together, either making it ourselves while joking around in the kitchen, or going out on the town. I look forward to every experience we have and I can't wait to travel the world with you.
                    </p>
                    <br/>
                    <p>
                        Having all these experiences together has just made my love for you grow even more. I enjoy every moment we're together and you make me happier every day! I never thought last year I would meet the love of my life (on a dating app nonetheless), and yet here we are.
                    </p>
                    <br/>
                    <p>
                        Even though commitment was never something I pictured for myself and I know you feel the same, with you it doesn't feel scary or overwhelming at all. There's no other person I would rather spend my life with, and I can't wait for our future together wherever it may take us.
                    </p>
                    <br/>
                    <p>
                        I love you so much Sarah, here's to the first year of our exciting lifetime together! ❤️
                    </p>
                    <br/>
                    <p className="font-medium">- Jake</p>
                </ReviewSection>
                

                <div className="m-auto max-w-lg px-4 py-12">
                    <Verification
                        verified={verified}
                        startVerification={() => setIsModalOpen(true)}
                    />
                </div>
            </div>

            <Modal title="Verification" open={isModalOpen} setOpen={setIsModalOpen}>
                <p>
                    In order to show you all of the content on this page we need to verify your identity with a quiz. This quiz consists of questions only Sarah or Jacob would know the answers to. In order to pass you must get every question right.
                </p>
                <Quiz
                    questions={shuffleArray([...QUIZ_QUESTIONS_2020, ...QUIZ_QUESTIONS_2021, ...QUIZ_QUESTIONS_2022])}
                    onPass={() => {
                        setVerified(true);
                        setTimeout(() => setIsModalOpen(false), 5000);
                    }}
                    onFail={() => setVerified(false)}
                />
            </Modal>
        </div>
    );
};

export default Anniversary;
