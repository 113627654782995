import { QuizQuestion } from '../../common/quiz/quiz';

export const QUIZ_QUESTIONS_2020: Array<QuizQuestion> = [
    {
        id: 'nickname-you-gave-me',
        message: 'Select the emoji which corresponds to the nickname you gave me.',
        choices: {
            boat: {
                label: '🛶',
                isCorrect: true
            },
            frog: {
                label: '🐸',
                isCorrect: false
            },
            fries: {
                label: '🍟',
                isCorrect: false
            },
            bear: {
                label: '🐻',
                isCorrect: false
            }
        }
    },
    {
        id: 'nickname-i-gave-you',
        message: 'Select the emoji which corresponds to the nickname I gave you.',
        choices: {
            princess: {
                label: '👸🏻',
                isCorrect: false
            },
            butterfly: {
                label: '🦋',
                isCorrect: false
            },
            burrito: {
                label: '🌯',
                isCorrect: true
            },
            strawberry: {
                label: '🍓',
                isCorrect: false
            }
        }
    },
    {
        id: 'location-of-first-kiss',
        message: 'Select the location where we had our first kiss.',
        choices: {
            yorkville: {
                label: 'Yorkville',
                isCorrect: false
            },
            oldMill: {
                label: 'The Old Mill',
                isCorrect: true
            },
            philosophersWalk: {
                label: `University of Toronto, Philosopher's Walk`,
                isCorrect: false
            },
            trinityBellwoodsPark: {
                label: 'Trinity Bellwoods Park',
                isCorrect: false
            }
        }
    },
    {
        id: 'first-meal-i-made',
        message: 'Select the first meal I ever made you.',
        choices: {
            penneAllaVodka: {
                label: 'Penne alla vodka',
                isCorrect: false
            },
            macAndCheeseBake: {
                label: 'Macaroni and cheese bake',
                isCorrect: true
            },
            chickenRiceAndVegetables: {
                label: 'Chicken, rice, and vegetables',
                isCorrect: false
            },
            tacos: {
                label: 'Tacos',
                isCorrect: false
            }
        }
    },
    {
        id: 'first-trip-away-together',
        message: 'Select the destination of our first trip away together.',
        choices: {
            wasagaBeach: {
                label: 'Wasaga Beach, Ontario',
                isCorrect: false
            },
            ottawa: {
                label: 'Ottawa, Ontario',
                isCorrect: false
            },
            turkeyPoint: {
                label: 'Turkey Point, Ontario',
                isCorrect: false
            },
            duclos: {
                label: 'Duclos, Québec',
                isCorrect: true
            }
        }
    },
    {
        id: 'when-we-made-it-official',
        message: 'Select the month and year we made us "official".',
        choices: {
            march2021: {
                label: 'March 2021',
                isCorrect: false
            },
            february2021: {
                label: 'February 2021',
                isCorrect: false
            },
            january2021: {
                label: 'January 2021',
                isCorrect: true
            },
            december2020: {
                label: 'December 2020',
                isCorrect: false
            },
        }
    },
    {
        id: 'first-restaurant-we-had-dinner',
        message: 'Select the first restaurant we had dinner together.',
        choices: {
            sofia: {
                label: 'Sofia',
                isCorrect: true
            },
            cibo: {
                label: 'Cibo',
                isCorrect: false
            },
            theKeg: {
                label: 'The Keg',
                isCorrect: false
            },
            sabaiSabai: {
                label: 'Sabai Sabai',
                isCorrect: false
            },
        }
    },
    {
        id: 'our-first-date',
        message: 'Select ALL of the following which correspond to our first date.',
        choices: {
            subway: {
                label: 'We took the subway',
                isCorrect: true
            },
            uber: {
                label: `I got you an Uber`,
                isCorrect: false
            },
            drinks: {
                label: 'Had drinks together',
                isCorrect: false
            },
            gelato: {
                label: 'Went for gelato',
                isCorrect: true
            },
            walked: {
                label: 'Walked around Dundas Square',
                isCorrect: false
            },
            noneOfTheAbove: {
                label: 'None of the above',
                isCorrect: false
            },
        }
    },
    {
        id: 'the-day-we-got-engaged',
        message: 'Select the day we got engaged.',
        choices: {
            sept26: {
                label: 'Sunday September 26th, 2021',
                isCorrect: false
            },
            sept24: {
                label: `Friday September 24th, 2021`,
                isCorrect: false
            },
            sept3: {
                label: `Friday September 3rd, 2021`,
                isCorrect: false
            },
            august29: {
                label: `Sunday August 29th, 2021`,
                isCorrect: true
            },
            august28: {
                label: `Saturday August 28th, 2021`,
                isCorrect: false
            },
            noneOfTheAbove: {
                label: 'None of the above',
                isCorrect: false
            },
        }
    }
]

export const QUIZ_QUESTIONS_2021: Array<QuizQuestion> = [
    {
        id: 'first-tropical-getaway',
        message: 'Select the destination of our first tropical getaway.',
        choices: {
            cuba: {
                label: 'Cuba',
                isCorrect: false
            },
            aruba: {
                label: `Aruba`,
                isCorrect: true
            },
            dominicanRepublic: {
                label: `Dominican Republic`,
                isCorrect: false
            },
            mexico: {
                label: `Mexico`,
                isCorrect: false
            }
        }
    },
    {
        id: 'first-anniversary-gift',
        message: 'Select ALL of the anniversary gifts we gave each other for our 1 year anniversary.',
        choices: {
            whiteGoldBracelet: {
                label: `White gold bracelet`,
                isCorrect: false
            },
            harryPotterNecklace: {
                label: `Harry Potter necklace`,
                isCorrect: true
            },
            harryPotterEarrings: {
                label: 'Harry Potter earrings',
                isCorrect: false
            },
            vessiSneakers: {
                label: `Vessi sneakers`,
                isCorrect: false
            },
            goldBracelet: {
                label: `Gold bracelet`,
                isCorrect: false
            },
            silverBracelet: {
                label: `Sterling silver bracelet`,
                isCorrect: true
            }
        }
    },
    {
        id: 'bc-rental-car-color',
        message: 'Select the color of our rental car when we first visited British Columbia.',
        choices: {
            silver: {
                label: 'Silver',
                isCorrect: false
            },
            black: {
                label: `Black`,
                isCorrect: false
            },
            white: {
                label: `White`,
                isCorrect: true
            },
            forestGreen: {
                label: `Forest green`,
                isCorrect: false
            }
        }
    },
    {
        id: 'fill-in-phrase-ending',
        message: 'Select the proper reaction to "Shama-lama".',
        choices: {
            lamaLama: {
                label: 'Lama-lama',
                isCorrect: false
            },
            dingDong: {
                label: `Ding-dong`,
                isCorrect: true
            },
            casualWink: {
                label: '😉👉🏻',
                isCorrect: false,
            },
        }
    },
]

export const QUIZ_QUESTIONS_2022: Array<QuizQuestion> = [
    {
        id: 'best-resort-stayed-at',
        message: 'Select the destination of the best resort we\'ve stayed at together.',
        choices: {
            nido: {
                label: 'Nido 🇬🇷',
                isCorrect: true
            },
            holidayInn: {
                label: `Holiday Inn 🇦🇼`,
                isCorrect: false
            },
            sandals: {
                label: `Sandals 🇯🇲`,
                isCorrect: false
            },
            ritzCarelton: {
                label: `Ritz Carelton 🇰🇾`,
                isCorrect: false
            }
        }
    },
    {
        id: 'our-lucky-number',
        message: 'Select our lucky number ☘️',
        choices: {
            eight: {
                label: '8',
                isCorrect: false
            },
            five: {
                label: `5`,
                isCorrect: false
            },
            nine: {
                label: `9`,
                isCorrect: true
            },
            none: {
                label: `None of the above`,
                isCorrect: false
            }
        }
    },
    {
        id: 'our-first-house-keys',
        message: 'Select the date we got the keys for our first house together 🏡',
        choices: {
            sept8: {
                label: 'September 8th, 2023',
                isCorrect: false
            },
            sept1: {
                label: `September 1st, 2023`,
                isCorrect: true
            },
            aug31: {
                label: `August 31st, 2023`,
                isCorrect: false
            },
            sept21: {
                label: `September 21st, 2023`,
                isCorrect: false
            }
        }
    }
]