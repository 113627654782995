import React from 'react';

type VerifiedProps = {
    verified: boolean;
    startVerification: () => void;
};

const Verification = ({ verified, startVerification }: VerifiedProps) => {

    const Verified = (
        <div className="bg-green-400 rounded-xl p-4">
            <span className="text-white text-lg font-bold">Verified!</span>
        </div>
    )

    return (
        <div className="text-center">
            {
                verified ? Verified : (
                    <div className="bg-gray-200 rounded-xl">
                        <div className="flex flex-col p-4">
                            <span className="text-lg font-bold mb-4">Are you Sarah or Jacob?</span>
                            <button
                                className="m-auto w-3/4 transition duration-150 ease-in-out transform hover:scale-125 bg-green-400 text-white font-semibold py-3 px-6 rounded-md"
                                onClick={startVerification}
                            >
                                Verify now
                            </button>
                        </div>
                    </div>
                )
            }
        </div>
    )
};

export default Verification;