import Close from './close';
import Logout from './logout';
import Heart from './heart';

type SizedIconProps = {
    size: 4 | 8 | 12 | 16 | 24;
    children: any;
    className?: string;
}

export const SizedIcon = ({ size, children, className }: SizedIconProps) => (
    <div className={`w-${size} h-${size} ${className ?? ''}`}>
        {children}
    </div>
);

export const Icons = {
    Close,
    Logout,
    Heart,
};