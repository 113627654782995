import React, { useEffect, useState } from 'react';

export type QuizChoice = {
    label: string;
    isCorrect: boolean;
};

export type QuizQuestion = {
    id: string;
    message: string;
    choices: Record<string, QuizChoice>;
};

export type QuizProps = {
    questions: QuizQuestion[];
    contentScrollTo?: Function;
    onPass: () => void;
    onFail: () => void;
};

const Quiz = ({ questions, contentScrollTo, onPass, onFail }: QuizProps) => {
    const [index, setIndex] = useState(0);
    const [passed, setPassed] = useState<boolean | undefined>(undefined);
    const [answers, setAnswers] = useState({} as Record<string, string[]>);

    const getFormFields = (question: QuizQuestion): Record<string, boolean> => 
        Object.keys(question?.choices ?? {}).reduce((form, key) => ({ ...form, [key]: false }), {});

    const question = questions[index];
    const formFields = getFormFields(question);

    const previousQuestion = () => {
        setAnswers({
            ...answers,
            [question.id]: Object.entries(formFields).filter(([_, chosen]) => chosen).map(([key, _]) => key)
        });
        setIndex(index - 1);
        if (contentScrollTo) contentScrollTo(0);
    }

    const nextQuestion = () => {
        setAnswers({
            ...answers,
            [question.id]: Object.entries(formFields).filter(([_, chosen]) => chosen).map(([key, _]) => key)
        });
        setIndex(index + 1);
        if (contentScrollTo) contentScrollTo(0);
    }

    useEffect(() => {
        const areAnswersCorrect = () => questions.every(({ id, choices }) => {
            const correctTotal = Object.values(choices).filter(({ isCorrect }) => isCorrect).length;
            const selected = answers[id];
            if (selected.length !== correctTotal) return false;
            return Object.entries(choices).every(([key, { isCorrect }]) => !isCorrect || selected.includes(key));
        });

        if (index >= questions.length) {
            const passed = areAnswersCorrect();
            setPassed(passed);
            const callback = passed ? onPass : onFail;
            callback();
            return;
        }
    }, [index, answers, questions, onFail, onPass])

    const Verified = (
        <div className="text-center bg-green-400 rounded-xl mt-4 p-4">
            <span className="text-white text-lg font-bold">Verified!</span>
        </div>
    );

    const UnableToVerify = (
        <div className="text-center bg-red-400 rounded-xl mt-4 p-4">
            <span className="text-white text-lg font-bold">Unable to Verify.</span>
        </div>
    );

    return (
        <div className="py-2">
            {passed === true && Verified}
            {passed === false && UnableToVerify}
            {question && passed === undefined && (
                <div>
                    <form onSubmit={e => {
                        e.preventDefault();
                        nextQuestion();
                    }}>
                        <p className="py-2"><span className="font-semibold">Question #{index + 1}:</span> {question.message}</p>
                        {
                            Object.entries(question.choices).map(([id, choice]) => (
                                <div key={`${question.id}-${id}`} className="flex items-center w-full p-2">
                                    <input
                                        id={`${question.id}-${id}`}
                                        value={`${formFields[id]}`}
                                        type="checkbox"
                                        onChange={event => formFields[id] = event.target.checked}
                                    />
                                    <label className="pl-2 font-semibold">{choice.label}</label>
                                </div>
                            ))
                        }
                        <div className="flex flex-wrap justify-end mt-4 py-2">
                            <button
                                type="button"
                                className="transition duration-150 ease-in-out transform disabled:scale-100 disabled:bg-gray-300 hover:scale-125 bg-red-400 text-white font-semibold my-2 w-full sm:w-52 py-3 px-6 rounded-md"
                                disabled={index === 0}
                                onClick={previousQuestion}
                            >
                                Previous question
                            </button>
                            <button
                                type="submit"
                                className="transition duration-150 ease-in-out transform-none sm:transform hover:scale-125 bg-blue-400 text-white font-semibold w-full sm:w-52 my-2 mx-0 sm:mx-8 py-3 px-6 rounded-md"
                            >
                                { index === questions.length - 1 ? 'Submit answers' : 'Next question' }
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default Quiz;
