import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import DeveloperToggle from '../common/developer-toggle/developer-toggle';
import Maintenance from '../common/maintenance/maintenance';
import { Anniversary } from '../pages';

const App = () => {
  return (
    <DeveloperToggle enabled={false}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Maintenance} />
          <Route exact path="/anniversary" component={Anniversary} />
        </Switch>
      </BrowserRouter>
    </DeveloperToggle>
  )
};

export default App;
