export const DEVELOPER_FLAGS = Object.freeze({
    GLOBAL: ['DEVELOPER-GLOBAL', '97LRZX2VNO'],
    ANNIVERSARY_DATE: ['DEVELOPER-ANNIVERSARY-DATE', 'NBIVX43L83'],
})

export const isDeveloperAuthorized = ([key, secret]: string[]): boolean => {
    const developerSecret = localStorage.getItem(key);
    return secret === developerSecret
}

export const unauthorizeDeveloper = ([key, _]: string[]): void => {
    localStorage.removeItem(key);
    window.location.reload();
}