import React from 'react';

const Maintenance = () => (
    <div className="absolute right-0 left-0 top-0 p-6">
        <div className="m-auto rounded-xl max-w-2xl bg-gray-200 shadow-sm border-gray-300 border-solid border-2">
            <div className="p-6">
                <h1 className="font-bold text-2xl">Maintenance</h1>
                <p>The site is currently under maintenance, please come back later!</p>
            </div>
        </div>
    </div>
);

export default Maintenance;